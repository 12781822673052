import { Outlet, Route, Routes, useNavigate } from "react-router-dom"
import { useEffect, useMemo } from "react";
import { Box, Container, CssBaseline, Divider, Grid, ThemeProvider, Typography, alpha } from "@mui/material";
import background from "./style/background.jpg";
import { useAppSelector } from "./app/hooks";
import { getCustomTheme } from "./features/theme/customMuiTheme";
import Header from "./features/header/Header";
import { routes } from "./routes";
import axios from "axios";
import { loadDayjsConfig } from "./config/dayjsConfig";
import AxiosLoading from "./features/loading/AxiosLoading";
import 'bootstrap/dist/css/bootstrap.min.css';

export const isDepevopment = () => ["http://127.0.0.1", "http://localhost"].some(e => window.location.origin.includes(e));
axios.defaults.baseURL = (isDepevopment() ? "http://localhost:8080" : `https://votaciones.${location.host}`);

loadDayjsConfig();

export default function App() {
  const themeMode = useAppSelector(state => state.theme.mode);
  const theme = useMemo(() => getCustomTheme(themeMode), [themeMode]);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.search.startsWith("?vts")) {
      const url = new URL(JSON.parse(`"${location.href}"`));
      navigate(`${routes.votePage.path}${url.search}`);
    }
    else if (location.search.startsWith("?overrideVTS")) {
      const url = new URL(JSON.parse(`"${location.href}"`));
      navigate(`${routes.resetVotePage.path}${url.search}`);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ background: `url(${background})`, boxShadow: `inset 0 0 0 1000px ${alpha(theme.palette.background.default, 0.90)}`, minHeight: "100vh" }}>
        <Routes >
          <Route path="/" element={<Layout />}>
            {Object.values(routes).map((e, index) => (
              <Route key={index} index={e.path === "/" ? true : false} path={e.path} element={e.element} />
            ))}
          </Route>
        </Routes>
        <AxiosLoading />
      </div>
    </ThemeProvider>
  )
}

const Layout = () => {
  return (
    <>
      <Header />
      <Container maxWidth="lg" sx={{ py: { xs: 2, lg: 4 } }}>
        <Outlet />

        {/* Footer */}
        <Grid container textAlign="center" mt={0} spacing={3}>
          <Grid item xs={12}>
            <Box width="80%" maxWidth={500} display="inline-block"><Divider /></Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">NUEVAS TECNOLOGÍAS - JUPOL NACIONAL</Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  )
};


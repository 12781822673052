import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container, { ContainerProps } from '@mui/material/Container';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { ThemeSwitch } from '../theme/ThemeSwitch';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setThemeMode } from '../theme/themeSlice';
import logo from "./logo.png";

export default function Header() {
    const dispatch = useAppDispatch();
    const mode = useAppSelector(state => state.theme.mode);
    const image = <img src={logo} alt="JUPOL" height={60} />
    return (
        <React.Fragment>
            <AppBar elevation={0} position="relative" sx={{backgroundColor: "primary.contrastText"}}>
                <Toolbar>
                    <Container disableGutters>
                        <Grid container>
                            <Grid item sm={4} display={{ xs: "none", sm: "flex" }} alignItems="center">
                                {image}
                            </Grid>
                            <Grid item xs={8} sm={4} display="flex" alignItems="center" justifyContent={{ xs: "start", sm: "center" }}>
                                <Typography color="text.primary" variant="h5" fontFamily="ethnocentric">
                                    Votaciones
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} display="flex" alignItems="center" justifyContent="end">
                                <ThemeSwitch mode={mode} onChange={mode => dispatch(setThemeMode(mode))} label="" />
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}
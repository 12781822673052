import { PaletteMode } from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '../../app/createAppSlice';

interface ThemeState {
  mode: PaletteMode,
}

const initialState: ThemeState = {
  mode: "dark",
};

export const themeSlice = createAppSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeMode: (state, action: PayloadAction<PaletteMode>) => {
      state.mode = action.payload;
    },
  },
});

export const { setThemeMode } = themeSlice.actions;
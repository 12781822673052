import React from "react";
import { lazy } from "react";
import Loading from "./features/loading/Loading";



const CensusPage = lazy(() => import("./features/checkCensus/CensusPage"));
const CensusFormPage = lazy(() => import("./features/checkCensus/CensusFormPage"));
const VotePage = lazy(() => import("./features/vote/VotePage"));
const SuccessVotePage = lazy(() => import("./features/vote/SuccessVotePage"));
const DuplicateVotePage = lazy(() => import("./features/vote/DuplicateVotePage"));
const UnactiveVotePage = lazy(() => import("./features/vote/UnactiveVotePage"));
const ResetVotePage = lazy(() => import("./features/resetVote/ResetVotePage"));
const ParticipationPage = lazy(() => import("./features/participation/ParticipationPage"));
const EndorsementPage = lazy(() => import("./features/endorsements/EndorsementPage"));
const DownloadEndorsementPage = lazy(() => import("./features/endorsements/DownloadEndorsementPage"));
const SuccessEndorsementPage = lazy(() => import("./features/endorsements/SuccessEndorsementPage"));
const DuplicateEndorsementPage = lazy(() => import("./features/endorsements/DuplicateEndorsementPage"));


export const addLoading = (children: JSX.Element) => (
    <React.Suspense fallback={<Loading open />}>
        {children}
    </React.Suspense>
)

export const routes = {
    censusFormPage: { path: "/", element: addLoading(<CensusFormPage />) },
    censusPage: { path: "/censo", element: addLoading(<CensusPage />) },
    votePage: { path: "/votacion", element: addLoading(<VotePage />) },
    successVotePage: { path: "/votacion/emitido", element: addLoading(<SuccessVotePage />) },
    duplicateVotePage: { path: "/votacion/ya-contabilizado", element: addLoading(<DuplicateVotePage />) },
    unactiveVotePage: { path: "/votacion/no-activa", element: addLoading(<UnactiveVotePage />) },
    participationPage: { path: "/votacion/:eek/participacion", element: addLoading(<ParticipationPage />) },
    resetVotePage: { path: "/restablecer-voto", element: addLoading(<ResetVotePage />) },
    endorsementPage: { path: "/avales", element: addLoading(<EndorsementPage />) },
    downloadEndorsementPage: { path: "/avales/candidatos", element: addLoading(<DownloadEndorsementPage />) },
    successEndorsementPage: { path: "/avales/emitido", element: addLoading(<SuccessEndorsementPage />) },
    duplicateEndorsementPage: { path: "/avales/ya-contabilizado", element: addLoading(<DuplicateEndorsementPage />) },
}
import { Backdrop, Box, CircularProgress, LinearProgress } from "@mui/material";
import logo from './jupol.png';

export default function Loading(props: { open: boolean }) {
    return (
        <Backdrop open={props.open}
            sx={{
                bgcolor: 'rgba(50, 50, 50, 0.8)',
                color: 'grey.500',
                zIndex: (theme: any) => theme.zIndex.modal + 2,
                flexDirection: 'column',
            }}
        >
            <div style={{ background: `url(${logo}) top center / contain no-repeat` }}>
                <CircularProgress color="inherit" size="50vw" thickness={0.3}
                    sx={{ maxWidth: "300px", maxHeight: "300px" }}
                />
            </div>
            <Box sx={{ width: '50%', maxWidth: '350px', mt: 3, mb: 15, color: 'grey.50' }}>
                <LinearProgress color="inherit" />
            </Box>
        </Backdrop>
    );
}